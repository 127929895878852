import React from 'react'
import { Link } from 'gatsby'
import resume from './../components/resume.pdf'
import {Container, Row, Col} from 'react-bootstrap'

const Footer = (props) => {
  return (
    <footer className="footer mt-auto">
      <Container>
        <Row>
          <Col xs={12} className="footer__grid">
            <ul>
              {/* <li>Navigation</li> */}
              {/* <ul>
                <li>
                  <Link to="/about">About</Link>
                </li> */}
                {/* <li>
                  <Link to="/blog">Notes</Link>
                </li> */}
                {/* <li>
                  <a href={resume} download>
                    Resume
                  </a>
                </li> */}
              {/* </ul> */}
            </ul>
          </Col>
          <p className="footer__copyright">
            © {new Date().getFullYear()} Adam Doe
          </p>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer

import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Nav, Navbar } from 'react-bootstrap'
import resume from './resume.pdf'
import ThemeContext from '../context/ThemeContext'
import {Container, Row, Col} from 'react-bootstrap';
import { FaRegLightbulb, FaLightbulb, FaMoon, FaSun} from 'react-icons/fa'

const Navigation = () => {
  const { theme, handleThemeChange } = useContext(ThemeContext)

  return (
    <header>
      <Container>
        <Row>
          <Col xs={12}>
            <Navbar
              expand="lg"
              variant={theme === 'theme--light' ? 'light' : 'dark'}
            >
              <Navbar.Brand>
                <Link exact className="navbar-brand logo" to="/">
                  adam doe
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="">
                <Nav className="d-flex align-items-center">
                  {/* <li className="nav-item">
                    <Link
                      exact
                      to="/about"
                      className="nav-link"
                      activeClassName="active"
                    >
                      About
                    </Link>
                  </li> */}
                  {/* <li className='nav-item'>
										<Link exact to='/work' className='nav-link' activeClassName='active'>
											Work
										</Link>
									</li> */}
                  {/* <li className="nav-item">
                    <a href={resume} className="nav-link" download>
                      Resume
                    </a>
                  </li> */}
                  {/* <label class="switch">
										<input 
											type="checkbox"
											checked={ checked }
											onClick={ () => {
												setChecked(!checked)
												handleThemeChange( checked ) 
											}}
										/>
										<span class="slider round"></span>
									</label> */}
                  {theme === 'theme--dark' ? (
                    <FaSun
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleThemeChange('theme--dark')
                      }}
                      className="theme--dark d-none d-lg-block"
                    />
                  ) : (
                    <FaMoon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleThemeChange('theme--light')
                      }}
                      className="theme--light d-none d-lg-block"
                    />
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  )
}
export default React.memo(Navigation)

import React from 'react'
import './../styles/layout.scss'
import Navigation from './navigation'
import Helmet from 'react-helmet'
import favicon from '../img/favicon.ico'
import Footer from './Footer'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
deckDeckGoHighlightElement()

const Template = ({ children }) => (
  <>
    <Helmet>
      <link rel="shortcut icon" type="image/png" href={favicon} />
      <link
        href="https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Navigation />
    {children}
    {/* <Footer /> */}
  </>
)
export default Template
